<template>
  <div class="box">
    <img src="../../assets/img/home_img.png" alt="">
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped lang="stylus">
  .box{
    width:100%;
    height 100%;
    img{
      width:100%;
      height 100%;
    }
  }
</style>